<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoGrupoEmpresa"
      :loading="loading"
      :paginate="true"
      sort-by="descricao"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Grupo Empresa</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer> </v-spacer>
          <RegisterBottom
            v-can-access="1721"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.grupo_empresa`]="{ item }">
        {{ item.grupo_empresa | TitleCase }}
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          icon
          v-can-access="1722"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          icon
          v-can-access="1723"
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->
        <IconBottom
          :name="'edit'"
          v-can-access="1722"
          @click="editItem(item)"
        />
        <IconBottom
          :name="'delete'"
          v-can-access="1723"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalGrupoEmpresa
      :dialog="dialog"
      :labelBtn="labelBtn"
      :empresasGrupo="empresasGrupo"
      @close="refresh()"
    />
  </div>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import sistema from "@/services/http/sistemaService";
import BaseTable from "@/components/shared/NewBaseTable.vue";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import ModalGrupoEmpresa from "@/components/empresa/grupoEmpresa/ModalGrupoEmpresa";

export default {
  name: "TabelaGrupoEmpresa",

  components: {
    BaseTable,
    RegisterBottom,
    ModalGrupoEmpresa,
    IconBottom
  },

  data() {
    return {
      loading: false,
      dialog: false,
      labelBtn: "Salvar",
      headers: [
        { text: "ID", value: "id_grupo_empresa" },
        { text: "Grupo Empresa", value: "grupo_empresa" },
        { text: "Ações", value: "acoes", align: "center" }
      ],
      infoGrupoEmpresa: [],
      empresasGrupo: {}
    };
  },

  methods: {
    async buscaGrupoEmpresa() {
      this.loading = true;
      const { data } = await sistema()
        .empresasGrupo()
        .show({
          per_page: -1
        });
      this.infoGrupoEmpresa = data.data;
      this.loading = false;
    },
    editItem(item) {
      this.empresasGrupo = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    refresh() {
      this.dialog = false;
      this.loading = true;
      this.buscaGrupoEmpresa();
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          `Deletar Grupo Empresa`,
          ` Deseja deletar Grupo Empresa ${item.grupo_empresa} ?`
        );
        sistema()
          .empresasGrupo(item.id_grupo_empresa)
          .delete(
            {},
            {
              notification: true,
              text: "Grupo Empresa deletada com sucesso!"
            }
          );
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar Grupo Empresa!"
        });
      } finally {
        this.buscaGrupoEmpresa();
      }
    }
  },

  mounted() {
    this.buscaGrupoEmpresa();
  }
};
</script>

<style></style>
